import {ButtonVariant} from "@components/components/Tree/types";
import React, {useMemo} from "react";
import {RegionSlug, RootStateLocation} from "../../../store/types";
import {generateRegionData} from "../../../utils/regions/regionUtils";
import {TreeItem} from "../components/Tree/ComplexTreeWrapper";
import {Url} from "next/dist/shared/lib/router/router";
import {LinkProps} from "next/link";
import {useUserSelectedLocation} from "src/utils/browser-storage/userSelectedLocation";
import RegionTreeSelector from "@components/Region/RegionTreeSelector";

interface ReduxRegionTreeSelector {
  locations?: RootStateLocation[];
  buttonVariant?: ButtonVariant;
  initialRegionSlug?: string;
  onSelect?: (slug: RegionSlug) => void;
  hideVirtualOnly?: boolean;
  inClinicSpecialtyIds?: string[];
  getHref?: (item: TreeItem<string>) => Url | undefined;
  getAs?: (item: TreeItem<string>) => LinkProps["as"] | undefined;
  shallow?: LinkProps["shallow"];
}

const ReduxRegionTreeSelector: React.FC<ReduxRegionTreeSelector> = ({
  buttonVariant = "primary",
  onSelect,
  initialRegionSlug,
  inClinicSpecialtyIds,
  hideVirtualOnly = false,
  getHref,
  getAs,
  shallow,
  locations,
}) => {
  const {regionSlug: selectedFromLocalStorage = "sf-bay-area"} = useUserSelectedLocation();

  const selectedRegion = initialRegionSlug ?? selectedFromLocalStorage;

  const regions = useMemo(
    () => (locations ? generateRegionData(locations, inClinicSpecialtyIds, hideVirtualOnly) : null),
    [hideVirtualOnly, inClinicSpecialtyIds, locations],
  );

  return regions ? (
    <RegionTreeSelector
      buttonVariant={buttonVariant}
      getAs={getAs}
      getHref={getHref}
      onSelect={onSelect}
      regions={regions}
      selectedRegionSlug={selectedRegion}
      shallow={shallow}
    />
  ) : null;
};

export default React.memo(ReduxRegionTreeSelector);
